<template>
  <div :class="styles.root">
    <Header/>
    <main :class="styles.main">
      <slot/>
    </main>
    <Footer/>
    <AuthModal/>
    <CookieModal/>
    <PageLoader v-if="isActive"/>
  </div>
</template>

<script lang="ts" setup>
import styles from './MainLayout.module.scss';
import { Header } from '~/widgets/Header';
import { Footer } from '~/widgets/Footer';
import { AuthModal } from '~/features/auth';
import { CookieModal } from '~/features/CookieModal';
import { PageLoader } from '~/shared/ui/loader';
import { usePreloader } from '~/features/loader';
import { storeToRefs } from 'pinia';

const { isActive } = storeToRefs(usePreloader());
</script>