import { routerPaths } from '~/shared/config/router';

export const MENU_ITEMS = [
  {
    label: 'Contact Us',
    href: routerPaths.main,
    scrollTo: false,
  },
  {
    label: 'How It Works',
    href: routerPaths.main,
    scrollTo: 'howIsWork',
  },
  {
    label: 'About Us',
    href: routerPaths.main,
    scrollTo: 'aboutUs',
  },
];