<template>
  <div :class="styles.root">
    <ProfileDropdown v-if="isAuthenticated" />
    <AuthButton v-else :class="styles.onlyDesktop" />
    <HeaderMobileButton :class="styles.onlyMobile" />
  </div>
</template>

<script lang="ts" setup>
import styles from './HeaderAction.module.scss';
import { AuthButton } from '~/features/auth';
import { ProfileDropdown } from '~/features/profile';
import { HeaderMobileButton } from '../HeaderMobileButton';

const { isAuthenticated } = useSanctumAuth()
</script>