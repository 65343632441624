import { useRouter } from 'vue-router';

const useScrollNavigation = async () => {
  const router = useRouter();

  const scrollToElementId = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  const scrollToElement = async (link) => {
    if (link.scrollTo) {
      const currentRoute = router.currentRoute.value.path;

      if (currentRoute === link.href) {
        scrollToElementId(link.scrollTo);
      } else {
        await router.push(link.href);

        setTimeout(() => {
          scrollToElementId(link.scrollTo);
        }, 300);
      }
    } else {
      await router.push(link.href);
    }
  }

  return {
    scrollToElement,
  }
}

export { useScrollNavigation };